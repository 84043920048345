import { HOC_MDXWrapper } from 'nextra/setup-page'
import { pageMap } from '/ui/docs-site/.next/static/chunks/nextra-page-map-.mjs'
/*@jsxRuntime automatic*/
/*@jsxImportSource react*/
/*-- dri: Adam Locke --*/
import {useMDXComponents as _provideComponents} from "nextra/mdx";
const title = "Chronosphere documentation";
const frontMatter = {
  "title": "Chronosphere documentation",
  "description": "Learn about the various aspects of the Chronosphere documentation."
};
import {Cards} from '@/components';
export function useTOC(props) {
  return [];
}
function MDXLayout(props) {
  const _components = {
    h1: "h1",
    img: "img",
    p: "p",
    ..._provideComponents(),
    ...props.components
  };
  if (!Cards) _missingMdxReference("Cards", false);
  if (!Cards.Card) _missingMdxReference("Cards.Card", true);
  return <>{}{"\n"}<_components.h1>{"Chronosphere documentation"}</_components.h1>{"\n"}<_components.p>{"Select your Chronosphere product to view its product documentation."}</_components.p>{"\n"}<Cards rowGroup="2"><Cards.Card title="Chronosphere Observability Platform" titleBackgroundColor="#508642" titleTextColor="#FFFFFF" href="/overview" icon={<_components.img src="/doc-assets/chronosphere_logo_icon.png" alt="Chronosphere logo" width="30" height="80" />}><_components.p>{"A SaaS-based observability and monitoring platform made for cloud-native\ninfrastructures."}</_components.p><_components.p>{"Monitor telemetry data across your entire system to identify and solve issues."}</_components.p><_components.p>{"Minimize impact and downtime for your app and your users while controlling what\ndata you pay for."}</_components.p></Cards.Card><Cards.Card title="Chronosphere Telemetry Pipeline" titleBackgroundColor="#705DA0" titleTextColor="#FFFFFF" href="/pipelines" icon={<_components.img src="/doc-assets/pipeline_logo_icon.png" alt="Chronosphere logo" width="30" height="80" />}><_components.p>{"An observability pipeline platform for routing and processing telemetry data."}</_components.p><_components.p>{"Integrate with your existing observability tools to send logs, metrics, and\ntraces from any source to any destination."}</_components.p><_components.p>{"Connect to these sources and destinations through a variety of built-in\nintegration plugins."}</_components.p></Cards.Card></Cards></>;
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}


export default HOC_MDXWrapper(
  MDXLayout,
  '/',
  {"filePath":"pages/index.mdx",pageMap,frontMatter,title},
  typeof RemoteContent === 'undefined' ? useTOC : RemoteContent.useTOC
)